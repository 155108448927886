import React from 'react'
import styled from '@emotion/styled'
import { HeadingContainer } from '../components/common/typography'
import AppLayout from '../components/layouts/appLayout'
import SEO from '../components/seo'
import PrivacypolicyComponent from './privacypolicy'

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: 1rem;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 8rem;
  box-sizing: border-box;
  justify-content: center;
`

const PrivacyPolicyPage = () => {
  return (
    <AppLayout  >
      <SEO title="Policy" canonical="privacy-policy" />
      <HeadingContainer title="Privacy policy"></HeadingContainer>
      <Container>
        <PrivacypolicyComponent/>
      </Container>
    </AppLayout>
  )
}

export default PrivacyPolicyPage